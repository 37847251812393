import React, { Component } from "react";
import styled from "styled-components";
import { withPage } from "../../Page";

class TestPage extends Component {
  render() {
    return <Wrapper>Testing...</Wrapper>;
  }
}

const Wrapper = styled.div`
  padding: 10px;
  background: #e6e6e6;
  width: 100vw;
  height: 100vh;
`;

export default withPage(TestPage);
